import React, { useEffect, useState } from "react";
// import data from "../data/data.json";
import conditions from "../data/conditions.json";
function FormTwo({ data, setData }) {
  const selectedConstruction = sessionStorage.getItem("selectedConstruction");

  const [editable, setEditable] = useState(false);

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   fetch('/data.json')
  //     .then(response => response.json())
  //     .then(jsonData => setData(jsonData))
  //     .catch(error => console.error('Error loading data:', error));
  // }, []);

  // const handleCheckboxChange = (itemId, propertyName) => {
  //   const updatedData = data.map(item => {
  //     if (item.id === itemId) {
  //       return { ...item, [propertyName]: !item[propertyName] };
  //     }
  //     return item;
  //   });
  //   setData(updatedData);
  // };

  const handleCheckboxChange = (itemId, propertyName) => {
    const updatedData = data.map((section) => {
      const updatedSubSections = section.sub?.map((subSection) => {
        const updatedItems = subSection.data?.map((item) => {
          if (item.id === itemId) {
            const updatedItem = { ...item, [propertyName]: !item[propertyName] };
            [
              "COMPLIES",
              "DOES NOT COMPLY",
              "DESIGN DETAIL",
              "DESIGN CERTIFICATION",
              "NOT APPLICABLE",
              "PERFOMANCE SOLUTION", // <-- TYPO here "PERFO[R]MANCE", its everywhere throughtout the app; correcting might break other stuff
              "CAPABLE OF COMPLIANCE",
            ].forEach((field) => {
              if (field !== propertyName) {
                updatedItem[field] = false;
              }
            });
            return updatedItem;
          }
          return item;
        });
        return { ...subSection, data: updatedItems };
      });
      return { ...section, sub: updatedSubSections };
    });

    // Update the state with the new data
    setData(updatedData);

    // Safely retrieve and parse steptwoFormData from sessionStorage
    let storedCheckedData = {};
    const steptwoFormData = sessionStorage.getItem("steptwoFormData");
    if (steptwoFormData && steptwoFormData.trim() !== "") {
      try {
        storedCheckedData = JSON.parse(steptwoFormData);
      } catch (e) {
        console.error("Error parsing steptwoFormData from sessionStorage:", e);
        // If parsing fails, remove the invalid data and proceed with empty object
        sessionStorage.removeItem("steptwoFormData");
        storedCheckedData = {};
      }
    }

    // Determine if the checkbox is now checked or unchecked
    const isChecked = updatedData.some((section) =>
      section.sub.some((subSection) =>
        subSection.data.some(
          (item) => item.id === itemId && item[propertyName] === true
        )
      )
    );

    if (isChecked) {
      // Set the new checked property
      storedCheckedData[itemId] = propertyName;
    } else {
      // If the checkbox is being unchecked, remove it from stored data
      delete storedCheckedData[itemId];
    }

    // Update sessionStorage with the new checked checkboxes
    sessionStorage.setItem("steptwoFormData", JSON.stringify(storedCheckedData));
  };

  return (
      <form className="p-4 pt-2">
        <div>
          <div className="bg-light rounded-3 d-inline-block mb-4 p-3">
            <label className="">
              <strong>Type of Construction :</strong>{" "}
            </label>{" "}
            <input type="text" disabled value={selectedConstruction} />
          </div>
        </div>
        {data.map((selected) => 
          <React.Fragment key={selected.section}>
              <h4 className="multipleForm-heading bg-primaryLight p-2">
                {selected?.section}
              </h4>
              {selected?.sub?.map((obj) => 
                <React.Fragment key={obj.name}>
                    <h5 className="multipleForm-subHeading ">{obj.name}</h5>

                    <table className="table table-hover multipleForm-table">
                      <thead>
                        <tr>
                          <th
                            className="bg-light"
                            style={{ width: "10%" }}
                            scope="col"
                          ></th>
                          <th
                            className="bg-light"
                            style={{ width: "40%" }}
                            scope="col"
                          >
                            BCA CLAUSE
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "12%" }}
                            scope="col"
                          >
                            COMPLIES
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "12%" }}
                            scope="col"
                          >
                            DOES NOT COMPLY
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "12%" }}
                            scope="col"
                          >
                            DESIGN DETAIL
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "14%" }}
                            scope="col"
                          >
                            DESIGN CERTIFICATION
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "14%" }}
                            scope="col"
                          >
                            NOT APPLICABLE
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "14%" }}
                            scope="col"
                          >
                            PERFORMANCE SOLUTION
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "14%" }}
                            scope="col"
                          >
                            CAPABLE OF COMPLIANCE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {obj.data.map((tr) =>
                              tr.id && tr.bcaClause ? (
                                <tr key={tr.id}>
                                  <th scope="row">{tr.id}</th>
                                  <td>{tr.bcaClause}</td>

                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <label style={{ display: 'block', width: '100%', height: '100%', margin: 0, cursor: 'pointer' }}>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={tr.COMPLIES}
                                        onChange={() =>
                                          handleCheckboxChange(tr.id, "COMPLIES")
                                        }
                                      />
                                    </label>
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <label style={{ display: 'block', width: '100%', height: '100%', margin: 0, cursor: 'pointer' }}>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={tr["DOES NOT COMPLY"]}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            tr.id,
                                            "DOES NOT COMPLY"
                                          )
                                        }
                                      />
                                    </label>
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <label style={{ display: 'block', width: '100%', height: '100%', margin: 0, cursor: 'pointer' }}>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={tr["DESIGN DETAIL"]}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            tr.id,
                                            "DESIGN DETAIL"
                                          )
                                        }
                                      />
                                    </label>
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <label style={{ display: 'block', width: '100%', height: '100%', margin: 0, cursor: 'pointer' }}>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={tr["DESIGN CERTIFICATION"]}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            tr.id,
                                            "DESIGN CERTIFICATION"
                                          )
                                        }
                                      />
                                    </label>
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <label style={{ display: 'block', width: '100%', height: '100%', margin: 0, cursor: 'pointer' }}>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={tr["NOT APPLICABLE"]}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            tr.id,
                                            "NOT APPLICABLE"
                                          )
                                        }
                                      />
                                    </label>
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <label style={{ display: 'block', width: '100%', height: '100%', margin: 0, cursor: 'pointer' }}>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={tr["PERFOMANCE SOLUTION"]}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            tr.id,
                                            "PERFOMANCE SOLUTION"
                                          )
                                        }
                                      />
                                    </label>
                                  </td>
                                  <td>
                                    <label style={{ display: 'block', width: '100%', height: '100%', margin: 0, cursor: 'pointer' }}>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={tr["CAPABLE OF COMPLIANCE"]}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            tr.id,
                                            "CAPABLE OF COMPLIANCE"
                                          )
                                        }
                                      />
                                    </label>
                                  </td>
                                </tr>
                              ) : (
                                ""
                            )
                        )}
                      </tbody>
                    </table>
                </React.Fragment>
                
              )}
          </React.Fragment>
        )}
      </form>
  );
}

export default FormTwo;
