import React, { useState } from "react";
import axios from "axios";

function UploadForm() {
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [ckData, setCkData] = useState("Welcome to Ck Editor");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState({});

  const handleSection1FileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSection2FileChange = (e) => {
    setFile2(e.target.files[0]);
  };

  const UPLOAD_FILE = `${process.env.REACT_APP_BACKEND_URL}/admin/files`;
  const DOWNLOAD_DOC = `${process.env.REACT_APP_BACKEND_URL}/admin/files`;

  const handleFileUpload = async () => {
    if (!file || !file2) {
      alert("Please choose both section1 and section2 DOCX files to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("section_1", file);
    formData.append("section_3", file2);

    try {
      const response = await axios.post(UPLOAD_FILE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle the response from the server as needed
      console.log("Server Response:", response.data);
      setFile2(null);
      setFile(null);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const downloadDocument = async (document_name) => {
    try {
      // setIsLoading(true);
      setIsLoading({ ...isLoading, [document_name]: true });

      const response = await axios.get(`${DOWNLOAD_DOC}/${document_name}`, {
        responseType: "blob",
      });

      if (response.status === 404) {
        setErrorMessage("Document not found");
        return;
      }

      const blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = document_name;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
    } finally {
      // setIsLoading(false);
      setIsLoading({ ...isLoading, [document_name]: false });
    }
  };
  return (
    <div className="container@ ">
      <div className="row">
        <div className="col-lg-4">
          <h2>Upload DOCX File</h2>
          <div className="Admin-uploadUserBox mt-2">
            <h6>Section 1 :</h6>
            <div className="mb-3 d-flex gap-1">
              <input
                type="file"
                className="form-control"
                onChange={handleSection1FileChange}
                accept=".docx"
              />
              <div className="col-2 text-end ">
                <button
                  className="btn btn-sm btn-primary  "
                  onClick={() => {
                    downloadDocument("section_1");
                  }}
                >
                  Download
                  {isLoading[document.document_name] ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                </button>
                {/* <button className="btn btn-sm btn-warning me-2" onClick={() => { downloadPDF(document.document_name) }}>Download Pdf</button> */}
              </div>
            </div>

            <h6>Section 3 :</h6>
            <div className="mb-3 d-flex gap-1">
              <input
                type="file"
                className="form-control"
                onChange={handleSection2FileChange}
                accept=".docx"
              />
              <div className="col-2 text-end">
                <button
                  className="btn btn-sm btn-primary  "
                  onClick={() => {
                    downloadDocument("section_3");
                  }}
                >
                  Download
                  {isLoading[document.document_name] ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                </button>
                {/* <button className="btn btn-sm btn-warning me-2" onClick={() => { downloadPDF(document.document_name) }}>Download Pdf</button> */}
              </div>
            </div>
            <button className="btn bg-primary" onClick={handleFileUpload}>
              Upload
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default UploadForm;
