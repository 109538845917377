import React, { useEffect, useState } from "react";
import LoginForm from "./form/LoginForm";
import MultiStepForm from "./form/MultiStepForm";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AdminPanel from "./components/AdminPanel";
import AdminLoginForm from "./form/adminLogin";
import DocumentList from "./components/DocumentList";
import './form/index.css'
import Sidebar from "./form/SideBar";
import SideMenu from "./components/SideMenu";
import TopNavbar from "./components/Navbar";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserListComponent from "./components/UserList";
import User from "./Routes/User/User";
import Admin from "./Routes/Admin/Admin";

const App = () => {
  const clientId = sessionStorage.getItem('clientId')
  const admin = sessionStorage.getItem('admin')
  const [isLoggedIn, setIsLoggedIn] = useState(clientId);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(admin);



  if (process.env.NODE_ENV === 'production') {
    console.log = function() {};
  }




  useEffect(() => {
    if (!isLoggedIn) {
      sessionStorage.removeItem("formData");
      sessionStorage.removeItem("selectedConstruction");
      sessionStorage.removeItem("selectionType");
      sessionStorage.removeItem("classification");
      sessionStorage.removeItem("buildingContain")
    }
  }, [isLoggedIn]);


  function DocumentListWrapper({ userId }) {
    return (
      <>
      <div className="container">
      <DocumentList userId={clientId}/>
      {/* Render the inputs for step 3 */}
      </div>
    </>
    );
  }

  return (
    <Router>
      <div>
        <ToastContainer />
        <Routes>
          <Route
            path="/*"
            element={<User />}
          />
          <Route
          path="/admin/*"
          element={<Admin />}
        />
           {/* <Route
            path="/"
            element={isLoggedIn ? <Navigate to="/form" /> : <LoginForm onLoginSuccess={setIsLoggedIn} />}
          />
          <Route
            path="/form"
            element={isLoggedIn ? <MultiStepForm /> : <Navigate to="/" />}
          />
          <Route
            path="/reset-password"
            element={<ResetPasswordForm />}
          />
          <Route
            path="/documents"
            element={isLoggedIn ? <DocumentListWrapper userId={clientId} /> : <Navigate to="/" />}
          /> */}
          {/* <Route
            path="/admin"
            element={isAdminLoggedIn ? <AdminPanel setIsAdminLoggedIn={setIsAdminLoggedIn} /> : <Navigate to="/admin/login" />}
          />
          <Route
            path="/admin/login"
            element={isAdminLoggedIn ? <Navigate to="/admin" /> : <AdminLoginForm onLogin={setIsAdminLoggedIn} />}
          />
          <Route
            path="admin/users"
            element={<UserListComponent />}
          />
          <Route
            path="admin/add-user"
            element={<AdminPanel />}
          />
          <Route
            path="admin/documents"
            element={<DocumentList />}
          />
          <Route
            path="admin/upload-documents"
            element={<AdminPanel />}
          />  */}
        </Routes>

      </div>
    </Router>
  );
};

export default App;
