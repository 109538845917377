import React, { useState } from "react";
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import Sidebar from "../../form/SideBar";
import SideMenu from "../../components/SideMenu";
import AdminLoginForm from "../../form/adminLogin";
import UserListComponent from "../../components/UserList";
import UserForm from "../../components/UserForm";
import UploadForm from "../../components/UploadForm";
import DocumentList from "../../components/DocumentList";
import Section from "../../components/Section";

function Admin() {
  const admin = window.sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const [currentUserID, setCurrentUserID] = useState("");

  const ProtectedRoute = ({ children }) => {
    if (!admin) {
      return <Navigate to="/admin/login" />;
    }

    return children;
  };

  // <div className="admin-panel container-fluid h-100 ">
  //   <div className="row h-100">
  //     <SideMenu setIsAdminLoggedIn={setIsAdminLoggedIn} selectedFunctionality={selectedFunctionality} handleFunctionalityClick={handleFunctionalityClick} userCount={userCount} documentCount={documentCount} />

  //     <div className="col-md-10 p-4 overflow-auto">
  //       {renderFunctionality()}
  //     </div>
  //   </div>
  // </div>

  const Layout = () => {
    return (
      <>
        <div className="admin-panel container-fluid h-100 ">
          <div className="row h-100">
            <SideMenu />
            <div className="col-md-10 p-4 overflow-auto">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/"
          element={<UserListComponent setCurrentUserID={setCurrentUserID} />}
        />
        <Route path="/add-user" element={<UserForm />} />
        <Route path="/upload-documents" element={<UploadForm />} />
        <Route
          path="/documents"
          element={
            <>
              <h2>Document List</h2>
              <DocumentList />
            </>
          }
        />
        <Route
          path="/user/:id"
          element={<DocumentList userId={currentUserID} />}
        /> <Route
        path="/section"
        element={
          <>
            <h2>Section</h2>
            <Section />
          </>
        }
      />
      </Route>

     
      <Route
        path="/login"
        element={admin ? <Navigate to="/admin" /> : <AdminLoginForm />}
      />
    </Routes>
  );
}

export default Admin;
