import React, { memo, useEffect, useRef, useState } from "react";
import FormTwo from "../components/FormTwo";
import axios from "axios";
import Toastify from "toastify-js";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import conditions from "../data/conditions.json";
const RenderStep2 = () => {
  const topRef = useRef(null);

  const [data, setData] = useState([]);
  const [payload, setPayload] = useState({});
  const clientId = sessionStorage.getItem("clientId");
  const navigate = useNavigate();
  const selectedConstruction = sessionStorage.getItem("selectedConstruction");
  const classification = (JSON.parse(sessionStorage.getItem("formData")) ?? {})["classification"];
  const classificationType = sessionStorage.getItem("selectionType");
  const formData = JSON.parse(sessionStorage.getItem("formData"));
  const [documentLoading, setDocumentLoading] = useState(false);
  // const [steptwoData, setSteptwoData] = useState([])

  const getbuildingContainStatus = (item) => {
    let buildingContain = (JSON.parse(sessionStorage.getItem("formData")) ?? {})["buildingContain"];
    let status = {
      stair: false,
      fireisolatedExits: false,
      lifts: false,
      fireisolatedlifts: false,
      atrium: false,
      childcareCentre: false,
      entertainmentVenue: false,
      residentialCareBuilding: false,
      school: false,
      openSpectatorStandsAndIndoorSportsStadiums: false,
    };
    if (item?.stair) {
      if (item?.stair == "True/False") {
        status.stair = true;
      } else if (item?.stair == "True") {
        if (buildingContain?.includes("Stairs")) {
          status.stair = true;
        } else {
          status.stair = false;
        }
      }
    }
    if (item.fireisolatedExits) {
      if (item.fireisolatedExits == "True/False") {
        status.fireisolatedExits = true;
      } else if (item?.fireisolatedExits == "True") {
        if (buildingContain?.includes("Fire-isolated exit")) {
          status.fireisolatedExits = true;
        } else {
          status.fireisolatedExits = false;
        }
      }
    }
    if (item.lifts) {
      if (item?.lifts == "True/False") {
        status.lifts = true;
      } else if (item?.lifts == "True") {
        if (buildingContain?.includes("Lift")) {
          status.lifts = true;
        } else {
          status.lifts = false;
        }
      }
    }
    if (item.fireisolatedlifts) {
      if (item?.fireisolatedlifts == "True/False") {
        status.fireisolatedlifts = true;
      } else if (item?.fireisolatedlifts == "True") {
        if (buildingContain?.includes("Fire-isolated lift")) {
          status.fireisolatedlifts = true;
        }
      }
    }
    if (item?.atrium) {
      if (item?.atrium == "True/False") {
        status.atrium = true;
      } else if (item?.atrium == "True") {
        if (buildingContain?.includes("Atrium")) {
          status.atrium = true;
        }
      }
    }
    if (item.childcareCentre) {
      if (item?.childcareCentre == "True/False") {
        status.childcareCentre = true;
      } else if (item?.childcareCentre == "True") {
        if (buildingContain?.includes("Childcare Centre")) {
          status.childcareCentre = true;
        }
      }
    }
    if (item.entertainmentVenue) {
      if (item?.entertainmentVenue == "True/False") {
        status.entertainmentVenue = true;
      } else if (item?.entertainmentVenue == "True") {
        if (buildingContain?.includes("Entertainment Venue")) {
          status.entertainmentVenue = true;
        }
      }
    }
    if (item.residentialCareBuilding) {
      if (item?.residentialCareBuilding == "True/False") {
        status.residentialCareBuilding = true;
      } else if (item?.residentialCareBuilding == "True") {
        if (buildingContain?.includes("Residential Care Building")) {
          status.residentialCareBuilding = true;
        }
      }
    }
    if (item.openSpectatorStandsAndIndoorSportsStadiums) {
      if (item?.openSpectatorStandsAndIndoorSportsStadiums == "True/False") {
        status.openSpectatorStandsAndIndoorSportsStadiums = true;
      } else if (item?.openSpectatorStandsAndIndoorSportsStadiums == "True") {
        if (
          buildingContain?.includes(
            "Open spectator stands and indoor sports stadiums"
          )
        ) {
          status.openSpectatorStandsAndIndoorSportsStadiums = true;
        }
      }
    }
    if (item.school) {
      if (item?.school == "True/False") {
        status.school = true;
      } else if (item?.school == "True") {
        if (buildingContain?.includes("School")) {
          status.school = true;
        }
      }
    }

    return status;
  };
  const checkDatawithId = (id) => {
    let d = [].concat(...conditions.map((obj) => obj.items));
    let finded = d.find((it) => it.id == id);
    if (finded) {
      let consArray = finded.construction.split("/");
      let consStatus = consArray?.includes(selectedConstruction);
      let findedClassification = finded.classification.split("/");
      const foundCommonElement = (classification ?? []).some((item) =>
        findedClassification.includes(item)
      );
      let classificationTypeStatus = false;
      if (finded?.multipleClassifications && classificationType) {
        if (finded.multipleClassifications == "True/False") {
          classificationTypeStatus = true;
        } else if (finded.multipleClassifications == "True") {
          if (classificationType != "single") {
            classificationTypeStatus = true;
          }
        }
      }

      let riseInStoreysStatus = false;
      const riseInStoreys = formData?.riseInStoreys;

      if (finded?.riseInStoreys && riseInStoreys) {
        let riseInStoreysArray = finded.riseInStoreys?.split("-");

        let min = parseInt(riseInStoreysArray[0]);
        let max = parseInt(riseInStoreysArray[1]);
        let input = parseInt(riseInStoreys);
        if (input >= min && input <= max) {
          riseInStoreysStatus = true;
        } else {
          riseInStoreysStatus = false;
        }
      }

      const effectiveHeight = formData?.effectiveHeight;
      let effectiveHeightStatus = false;
      if (finded?.effectiveHeight && effectiveHeight) {
        let effectiveHeightArray = finded?.effectiveHeight?.split("-");
        let min = parseFloat(effectiveHeightArray[0]);
        let max = parseFloat(effectiveHeightArray[1]);
        let input = parseFloat(effectiveHeight);
        if (input >= min && input <= max) {
          effectiveHeightStatus = true;
        } else {
          effectiveHeightStatus = false;
        }
      }
      const buildingArea = formData?.buildingArea;
      let buildingAreaStatus = false;
      if (buildingArea && finded?.buildingArea) {
        let buildingAreaArray = finded.buildingArea.split("-");
        let min = parseFloat(buildingAreaArray[0]);
        let max = parseFloat(buildingAreaArray[1]);
        let input = parseFloat(buildingArea);

        if (input >= min && input <= max) {
          buildingAreaStatus = true;
        } else {
          buildingAreaStatus = false;
        }
      }

      const largestFireCompartmentSize = formData?.largestFireCompartmentSize;
      let largestFireCompartmentSizeStatus = false;
      if (finded?.largestFireCompartmentSize && largestFireCompartmentSize) {
        let largestFireCompartmentSizeArray =
          finded.largestFireCompartmentSize.split("-");
        let min = parseFloat(largestFireCompartmentSizeArray[0]);
        let max = parseFloat(largestFireCompartmentSizeArray[1]);
        let input = parseFloat(largestFireCompartmentSize);
        if (input >= min && input <= max) {
          largestFireCompartmentSizeStatus = true;
        } else {
          largestFireCompartmentSizeStatus = false;
        }
      }
      const multipleFireCompartmentsType = sessionStorage.getItem(
        "multipleFireCompartmentsType"
      );
      let multipleFireCompartmentsTypeStatus = false;
      if (finded.multipleFireCompartments && multipleFireCompartmentsType) {
        if (finded.multipleFireCompartments == "True/False") {
          multipleFireCompartmentsTypeStatus = true;
        } else if (finded.multipleFireCompartments == "True") {
          if (multipleFireCompartmentsType != "single") {
            multipleFireCompartmentsTypeStatus = true;
          }
        }
      }
      const {
        stair,
        atrium,
        childcareCentre,
        entertainmentVenue,
        fireisolatedExits,
        fireisolatedlifts,
        lifts,
        openSpectatorStandsAndIndoorSportsStadiums,
        residentialCareBuilding,
        school,
      } = getbuildingContainStatus(finded);
      // console.log(id);
      // console.log(getbuildingContainStatus(finded));
      // console.log(foundCommonElement);
      // console.log(consStatus);
      // console.log(classificationTypeStatus);
      // console.log(riseInStoreysStatus);
      // console.log(effectiveHeightStatus);
      // console.log(buildingAreaStatus);
      // console.log(largestFireCompartmentSizeStatus);
      // console.log(multipleFireCompartmentsTypeStatus);
      let status =
        foundCommonElement &&
        consStatus &&
        classificationTypeStatus &&
        riseInStoreysStatus &&
        effectiveHeightStatus &&
        buildingAreaStatus &&
        largestFireCompartmentSizeStatus &&
        multipleFireCompartmentsTypeStatus &&
        stair &&
        atrium &&
        childcareCentre &&
        entertainmentVenue &&
        fireisolatedExits &&
        fireisolatedlifts &&
        lifts &&
        openSpectatorStandsAndIndoorSportsStadiums &&
        residentialCareBuilding &&
        school;

      return status;
    } else {
      return false;
    }
  };

  // useEffect(() => {
  //   fetch("/data.json")
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       var newData = jsonData;
  //       var clonedData = JSON.parse(JSON.stringify(newData));
  //       const updatedData = clonedData.map((section) => {
  //         const updatedSubSections = section.sub.map((subSection) => {
  //           const updatedItems = subSection.data.map((item) => {
  //             if (!checkDatawithId(item.id)) {
  //               return { ...item, ["NOT APPLICABLE"]: true };
  //             }
  //             return item;
  //           });

  //           return { ...subSection, data: updatedItems };
  //         });

  //         return { ...section, sub: updatedSubSections };
  //       });

  //       setData(updatedData);
  //     })
  //     .catch((error) => console.error("Error loading data:", error));

  //   // fetchStepOneData();
  // }, []);

  useEffect(() => {
    console.log('loaded from data');
    
    // Fetch data from /data.json
    fetch("/data.json")
      .then((response) => response.json())
      .then((jsonData) => {
        // Deep clone the JSON data to prevent mutation
        const clonedData = JSON.parse(JSON.stringify(jsonData));
        
        // Update data based on conditions
        const updatedData = clonedData.map((section) => {
          const updatedSubSections = section.sub.map((subSection) => {
            const updatedItems = subSection.data.map((item) => {
              if (!checkDatawithId(item.id)) {
                return { ...item, ["NOT APPLICABLE"]: true };
              }
              return item;
            });
            return { ...subSection, data: updatedItems };
          });
          return { ...section, sub: updatedSubSections };
        });

        // Retrieve and safely parse steptwoFormData from sessionStorage
        let storedCheckedData = {};
        const steptwoFormData = sessionStorage.getItem("steptwoFormData");
        if (steptwoFormData && steptwoFormData.trim() !== "") {
          try {
            storedCheckedData = JSON.parse(steptwoFormData);
          } catch (e) {
            console.error("Error parsing steptwoFormData from sessionStorage:", e);
            // If parsing fails, remove the invalid data and proceed with empty object
            sessionStorage.removeItem("steptwoFormData");
            storedCheckedData = {};
          }
        }

        // Apply the stored checked checkboxes to the loaded data
        const dataWithChecked = updatedData.map((section) => {
          const updatedSubSections = section.sub.map((subSection) => {
            const updatedItems = subSection.data.map((item) => {
              if (storedCheckedData[item.id]) {
                const checkedProperty = storedCheckedData[item.id];
                const updatedItem = { ...item, [checkedProperty]: true };
                // Ensure other checkboxes are false
                [
                  "COMPLIES",
                  "DOES NOT COMPLY",
                  "DESIGN DETAIL",
                  "DESIGN CERTIFICATION",
                  "NOT APPLICABLE",
                  "PERFORMANCE SOLUTION",
                  "CAPABLE OF COMPLIANCE",
                ].forEach((field) => {
                  if (field !== checkedProperty) {
                    updatedItem[field] = false;
                  }
                });
                return updatedItem;
              }
              return item;
            });
            return { ...subSection, data: updatedItems };
          });
          return { ...section, sub: updatedSubSections };
        });

        // Set the final data state
        setData(dataWithChecked);
      })
      .catch((error) => console.error("Error loading data:", error));
  }, []);

  const SECOND_API_URL = `${process.env.REACT_APP_BACKEND_URL}/generate-doc`;

  const handleNextStep = async () => {
    setDocumentLoading(true);
    const STEP2_URL = `${process.env.REACT_APP_BACKEND_URL}/stepForm/step-2`;

    const payload = {
      clientId: clientId,
      data: data,
    };

    try {
      const response = await axios.post(STEP2_URL, payload);
      if (response.status == 201) {
        const myPromise = new Promise((resolve, reject) =>
          axios
            .post(SECOND_API_URL, {
              clientId: clientId,
            })
            .then((secondResponse) => {
              setTimeout(() => {
                if (secondResponse.status === 201) {
                  // setDocumentLoading(false);
                  // sessionStorage.setItem("classification", []);
                  // sessionStorage.setItem("selectionType", "");
                  // sessionStorage.setItem(
                  //   "formData",
                  //   JSON.stringify({
                  //     client: "",
                  //     projectName: "",
                  //     address: "",
                  //     architect: "",
                  //     projectDescription: "",
                  //     jobNumber: "",
                  //     buildingContain: [],
                  //     classification: [],
                  //     selectedConstruction: "",
                  //     riseInStoreys: "",
                  //     effectiveHeight: "",
                  //     largestFireCompartmentSize: "",
                  //     buildingArea: "",
                  //   })
                  // );
                  // sessionStorage.setItem("selectedConstruction", "");
                  // sessionStorage.setItem("multipleFireCompartmentsType", "");
                  navigate("/step3");
                }
                resolve(secondResponse);
              }, 2000);
            })
            .catch((secondError) => {
              setDocumentLoading(false);
              reject(secondError);
            })
        );
        toast.promise(myPromise, {
          pending: "Generating document",
          success: "Document generated successfully",
          error: "Failed to generate document",
        });
      }
    } catch (error) {
      setDocumentLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
      <div ref={topRef} className="content">
        <div className="container multipleForm">
          <div className="row justify-content-center">
            <div className="col">
              <div className="bg-white pb-4 shadow-sm m-lg-5 my-4 border overflow-hidden rounded-3">
                <div>
                  <h3 className="bg-primary p-2">Step 2: Building Details</h3>
                  <FormTwo
                    data={data}
                    setData={setData}
                    setPayload={setPayload}
                  />
                  {/* Render the inputs for step 2 */}
                </div>
                <div className="mt-3 px-3">
                  <Link to="/step1">
                    <button className="btn border">Previous</button>
                  </Link>

                  <button
                    disabled={documentLoading}
                    className="btn bg-primary border ms-2"
                    onClick={handleNextStep}
                  >
                    {documentLoading ? (
                      <span
                        className="spinner-border spinner-border-sm ms-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default memo(RenderStep2);
