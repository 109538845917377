import axios from "axios";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const FORGOT_PASSWORD = `${process.env.REACT_APP_BACKEND_URL}/user/forgot-password`;
  const handleForgotPasswordClick = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter you email", {
        autoClose: 2000,
      });
      return 0;
    }

    try {
        setLoading(true)
      const response = await axios.post(FORGOT_PASSWORD, { email: email });
  
      if (response.status === 200) {
        setLoading(false)
        toast.success("Password reset link sent to your email", {
          autoClose: 3000,
        });
      } else {
        setLoading(false)
        toast.error(response.data.message, {
          autoClose: 3000,
        });

        // navigate("/form");
      }
    } catch (error) {
        setLoading(false)
      if (error.response) {
        console.error("Login error. Status:", error.response.status);
        toast.error(error.response.data.data.message, {
            autoClose: 3000,
          });
        console.error("Error data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  return (
    <div className="position-relative d-flex justify-content-center flex-column align-items-center vh-100">
      <div
        className="loginBG position-absolute "
        style={{
          background:
            "linear-gradient( to top ,rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)) , url(../../lognGenBG.jpg) center center/cover",
          backgroundSize: "cover",
        }}
      ></div>

      <div className="" style={{ minHeight: "20vh" }}>
        <h1
          className=""
          style={{
            textAlign: "center",
            fontSize: "90px",
            fontWeight: "bold",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
          }}
        >
          The Generator
        </h1>
      </div>
      <div className="container d-flex justify-content-center align-items-center ">
        <div className="loginBox border bg-primary rounded-3 box-shadow">
          <form onSubmit={handleForgotPasswordClick}>
            <h2>Forgot Password</h2>
            <hr></hr>
            <div className="mb-1">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button 
              disabled={loading}
              type="submit"
              className="btn mt-2 px-4 text-primary fw-bold bg-black"
            >
              Send reset link
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
