import React, { useState } from "react";
import ConfirmationDialog from './ConfirmationDialog';
import { useNavigate } from "react-router-dom";


const SideMenu = ({
  currentUserID,
  selectedFunctionality,
  handleFunctionalityClick,
  userCount,
  documentCount,
  setIsAdminLoggedIn,
}) => {
 
  const navigate = useNavigate()
  const handleLogout = async () => {
    sessionStorage.removeItem('admin')
    navigate('/admin')
  };
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const handleDeleteButtonClick = () => {
    // Show the confirmation dialog when the delete button is clicked
    setConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    // Handle the delete action here
    // Typically, you would make an API call or update state
    // After that, close the dialog
    setConfirmationOpen(false);
  };

  const handleCancelDelete = () => {
    // Close the dialog when cancel is clicked
    setConfirmationOpen(false);
  };
  return (
    <div className="sidebarList justify-content-between col-md-2 bg-dark@ text-light p-4 d-flex flex-column vh-100 posi tion-fixed">
      <div
        className={`menu-item ${
          selectedFunctionality === "users" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin")}
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex align-items-center">
          <i class="fa fa-users me-2" aria-hidden="true"></i>
          <span>All Users</span>
        </div>
        {userCount !== undefined && (
          // <span className="ml-2 ms-2">{userCount}</span>
          <span className="badge badge-primary">{userCount}</span>
        )}
      </div>
      <div
        className={`menu-item ${
          selectedFunctionality === "add User" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin/add-user")}
        style={{ cursor: "pointer" }}
      >
         <div className="d-flex align-items-center">
          <i class="fa fa-user-plus me-2"></i>
          <span>Add User</span>
        </div>
      </div>
      <div
        className={`menu-item ${
          selectedFunctionality === "documents" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin/documents")}
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex align-items-center">
          <i class="fa fa-file me-2" aria-hidden="true"></i>
            <span>Documents</span>
        </div>
        {documentCount !== undefined && (
          // <span className="ml-2 ms-2">{userCount}</span>
          <span className="badge badge-primary">{documentCount}</span>
        )}
      </div>
      <div
        className={`menu-item ${
          selectedFunctionality === "upload template" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin/upload-documents")}
        style={{ cursor: "pointer" }}
      >
         <div className="d-flex align-items-center">
            <i class="fa fa-file me-2" aria-hidden="true"></i>
            <span>Upload Documents</span>
          </div>
        
      </div>

      <div
        className={`menu-item ${
          selectedFunctionality === "section" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin/section")}
        style={{ cursor: "pointer" }}
      >
         <div className="d-flex align-items-center">
          <i class="fa fa-file me-2"></i>
          <span>Section</span>
        </div>
      </div>
      <div
        className="mt-auto mb-3 ms-5"
        onClick={handleLogout}
        style={{ cursor: "pointer" }}
      >
        <i className="fas fa-sign-out-alt"></i> Logout
      
      </div>
    </div>
  );
};

export default SideMenu;
