import { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import DocumentList from "../components/DocumentList";
import { Link } from "react-router-dom";
const RenderStep3 = () => {
  const clientId = sessionStorage.getItem("clientId");

  return (
    <div className="content">
      <div className="container multipleForm">
        <div className="row justify-content-center">
          <div className="col">
            <div className="bg-white pb-4 shadow-sm m-lg-5 my-4 border overflow-hidden rounded-3">
              <div>
                <h3 className="bg-primary p-2 ">Step 3: Documents</h3>
                {/* {documentLoading ? (
                  <div className="d-flex align-items-center gap-2 flex-column justify-content-center ">
                    <span
                      style={{ height: "80px", width: "80px" }}
                      className="spinner-border spinner-border-lg ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>

                    <p style={{ textAlign: "left", marginTop: "10px" }}>
                      Generating your document please wait!!!
                    </p>
                  </div>
                ) : ( */}
                    <DocumentList userId={clientId} />
                {/* )} */}
              </div>
              <div className="mt-3 px-3">
                <Link to="/step2">
                  <button className="btn border">Previous</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(RenderStep3);
