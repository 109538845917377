import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

function UserForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [admin, setAdmin] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const API_URL_USER = `${process.env.REACT_APP_BACKEND_URL}/admin/create-user`;
  const API_URL_ADMIN = `${process.env.REACT_APP_BACKEND_URL}/admin/create-admin`;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validatePassword(password)) {
      toast.error(
        "Invalid password. Password must be alphanumeric and at least 6 characters long."
      );
      return;
    }

    const newUser = {
      email,
      password,
      name,
    };

    const API_URL = admin ? API_URL_ADMIN : API_URL_USER; // Choose API based on admin checkbox

    try {
      const response = await axios.post(API_URL, newUser);
      setSuccessMessage(admin ? "Admin added successfully" : "User added successfully");
    } catch (error) {
      console.error("Error adding user/admin:", error);
      toast.error("There was an error processing your request.");
    }

    // Reset form
    setEmail("");
    setPassword("");
    setName("");
    setAdmin(false);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/;
    return re.test(password);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleAdminChange = (e) => {
    setAdmin(e.target.checked); // Update admin state based on checkbox status
  };

  // Global variable to remove $ for string inputs
  var sanatisedInput;

  // Global variable to store input for string conversion
  var stringConvert;

  return (
    <div className="container@">
      <div className="row Admin-addUser">
        <div className="col-lg-4">
          <div>
            <h2>Add User</h2>
            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
            <form className="Admin-addUserBox" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => {
                    // Forcing input to be string and removing $
                    sanatisedInput = e.target.value.replace(/\$/g, "");
                    stringConvert = sanatisedInput.toString();
                    //console.log(typeof e.target.value);
                    setName(stringConvert)}}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    // Forcing input to be string and removing $
                    sanatisedInput = e.target.value.replace(/\$/g, "");
                    stringConvert = sanatisedInput.toString();
                    //console.log(typeof e.target.value);
                    setEmail(stringConvert)}}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  id="make_admin"
                  checked={admin}
                  onChange={handleAdminChange}
                />
                <label htmlFor="make_admin" className="form-label ms-2">
                  is Admin?
                </label>
              </div>
              <button type="submit" className="btn bg-primary">
                {admin ? "Add Admin" : "Add User"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserForm;