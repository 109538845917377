import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function Section() {
  const [section, setSection] = useState("2");
  const [type, setType] = useState("com");
  const [value, setValue] = useState("");

  const API_GET_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/get-section`;
  const API_UPDATE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/update-section`;

  const handleSubmit = async (event) => {
    axios
      .post(`${API_UPDATE_URL}/${section}/${type}`,JSON.parse(value))
      .then((res) => {
        console.error(res);
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error(error.message);
        console.error("Error get section:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${API_GET_URL}/${section}/${type}`)
      .then((res) => {
        setValue(JSON.stringify(res.data, null, 2));

        console.error(res.data);
      })
      .catch((error) => {
        console.error("Error get section:", error);
      });
  }, [section, type]);
  
  return (
    <div
      className="container@"
      style={{
        height: "calc(100% - 50px)",
      }}
    >
      <div className="row Admin-addUser h-100">
        <div>
          <div className="Admin-addUserBox row m-0">
            <div className="col-lg-6">
              <label htmlFor="name" className="form-label">
                Select Section
              </label>
              <select
                id="dropdown"
                className="form-control"
                onChange={(e) => {
                  setSection(e.target.value);
                }}
              >
                <option value="2">Section B</option>
                <option value="3">Section C</option>
                <option value="4">Section D</option>
                <option value="5">Section E</option>
                <option value="6">Section F</option>
                <option value="7">Section G</option>
              </select>
            </div>
            <div className="col-lg-6">
              <label htmlFor="email" className="form-label">
                Select Type
              </label>
              <select
                id="dropdown2"
                className="form-control"
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <option value="com">COMPLIES</option>
                <option value="dnc">DOES NOT COMPLY</option>
                <option value="dd">DESIGN DETAIL</option>
                <option value="dc">DESIGN CERTIFICATION</option>
                <option value="ps">PERFORMANCE SOLUTION</option>
                <option value="coc">CAPABLE OF COMPLIANCE</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="col-lg-12 mt-3"
          style={{ height: "calc(100% - 120px)" }}
        >
          <div className="Admin-addUserBox h-100">
            <div className="mb-3" style={{ height: "calc(100% - 50px)" }}>
              <textarea
                type="test"
                id="con"
                value={value}
                style={{
                  width: "100%",
                  minHeight: "100%",
                  resize: "none",
                }}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <button className="btn bg-primary" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
