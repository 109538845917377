import React, { memo, useEffect, useRef, useState } from "react";
import FormOne from "../components/FormOne";
import axios from "axios";
import Toastify from "toastify-js";
import Sidebar from "./SideBar";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Option = () => {
  const navigate = useNavigate();
  const [secondOption, setSecondOption] = useState(false);
  return (
    <>
      <div className="content d-flex justify-content-center">
        {!secondOption && (
          <div className="d-grid" style={{ width: "200px" }}>
            <button
              className="btn bg-primary border mb-3"
              style={{ minHeight: "70px", fontSize: "30px" }}
              onClick={() => {
                setSecondOption(true);
              }}
            >
              BCA
            </button>
            <button
              className="btn bg-primary border mb-3"
              style={{ minHeight: "70px", fontSize: "30px" }}
              // onClick={handleNextStep}
            >
              Access
            </button>
            <button
              className="btn bg-primary border"
              style={{ minHeight: "70px", fontSize: "30px" }}
              // onClick={handleNextStep}
            >
              Fire
            </button>
          </div>
        )}
        {secondOption && (
          <div className="d-grid" style={{ width: "600px" }}>
            <button
              className="btn bg-primary border mb-3"
              style={{ minHeight: "70px", fontSize: "30px" }}
              onClick={() => {
                window.location.href = "/step1";
              }}
            >
              BCA Report
            </button>
            <button
              className="btn bg-primary border mb-3"
              style={{ minHeight: "70px", fontSize: "30px" }}
              // onClick={handleNextStep}
            >
              BCA Performance Solution Report
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Option);
